<template>

</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  watch: {

  },
  components: {},
  beforeRouteUpdate(to, from, next) {},
  mounted() {
    let role = localStorage.getItem('role');
    if (role == 4) {
      this.$router.push({
        path: "/main?type=1"
      })
    } else if (role == 3) {
      this.$router.push({
        path: "/main?type=2"
      })
    } else if (role == 2) {
      this.$router.push({
        path: "/index"
      })
    } else if (role == 1) {
      this.$router.push({
        path: "/index"
      })
    } else {
      this.$router.push({
        path: "/user/login"
      })
    }
  },
  actived() {

  },
  methods: {},
};
</script>
